/* eslint-disable */
export const LOGIN_CONSTANTS = {
  LOGIN_LOADING: 'LOGIN_LOADING',
  LOGIN_SUCESS: 'LOGIN_SUCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
};
export const FORGOT_CONSTANTS = {
  FORGOT_LOADING: 'FORGOT_LOADING',
  FORGOT_SUCESS: 'FORGOT_SUCESS',
  FORGOT_ERROR: 'FORGOT_ERROR',
};


export const VIEW_DEPARTMENT_CONSTANT = {
  VIEW_DEPARTMENT_LOADING: 'VIEW_DEPARTMENT_LOADING',
  VIEW_DEPARTMENT_SUCCESS: 'VIEW_DEPARTMENT_SUCCESS',
  VIEW_DEPARTMENT_ERROR: 'VIEW_DEPARTMENT_ERROR'
}
export const UPDATE_DEPARTMENT_CONSTANT = {
  UPDATE_DEPARTMENT_LOADING: 'UPDATE_DEPARTMENT_LOADING',
  UPDATE_DEPARTMENT_SUCCESS: 'UPDATE_DEPARTMENT_SUCCESS',
  UPDATE_DEPARTMENT_ERROR: 'UPDATE_DEPARTMENT_ERROR'
}
export const VIEW_ADMIN_CONSTANT = {
  VIEW_ADMIN_LOADING: 'VIEW_ADMIN_LOADING',
  VIEW_ADMIN_SUCCESS: 'VIEW_ADMIN_SUCCESS',
  VIEW_ADMIN_ERROR: 'VIEW_ADMIN_ERROR',
};
export const VIEW_DIRECTOR_CONSTANT = {
  VIEW_DIRECTOR_LOADING: 'VIEW_DIRECTOR_LOADING',
  VIEW_DIRECTOR_SUCCESS: 'VIEW_DIRECTOR_SUCCESS',
  VIEW_DIRECTOR_ERROR: 'VIEW_DIRECTOR_ERROR',
};
export const VIEW_ROLE_CONSTANT = {
  VIEW_ROLE_LOADING: 'VIEW_ROLE_LOADING',
  VIEW_ROLE_SUCCESS: 'VIEW_ROLE_SUCCESS',
  VIEW_ROLE_ERROR: 'VIEW_ROLE_ERROR',
};
export const VIEW_DEPO_CONSTANT = {
  VIEW_DEPO_LOADING: 'VIEW_DEPO_LOADING',
  VIEW_DEPO_SUCCESS: 'VIEW_DEPO_SUCCESS',
  VIEW_DEPO_ERROR: 'VIEW_DEPO_ERROR',
};
export const VIEW_DELIVERYSTAFF_CONSTANT = {
  VIEW_DELIVERYSTAFF_LOADING: 'VIEW_DELIVERYSTAFF_LOADING',
  VIEW_DELIVERYSTAFF_SUCCESS: 'VIEW_DELIVERYSTAFF_SUCCESS',
  VIEW_DELIVERYSTAFF_ERROR: 'VIEW_DELIVERYSTAFF_ERROR',
};
export const VIEW_AM_CONSTANT = {
  VIEW_AM_LOADING: 'VIEW_AM_LOADING',
  VIEW_AM_SUCCESS: 'VIEW_AM_SUCCESS',
  VIEW_AM_ERROR: 'VIEW_AM_ERROR',
};
export const VIEW_RSM_CONSTANT = {
  VIEW_RSM_LOADING: 'VIEW_RSM_LOADING',
  VIEW_RSM_SUCCESS: 'VIEW_RSM_SUCCESS',
  VIEW_RSM_ERROR: 'VIEW_RSM_ERROR',
};
export const VIEW_SM_CONSTANT = {
  VIEW_SM_LOADING: 'VIEW_SM_LOADING',
  VIEW_SM_SUCCESS: 'VIEW_SM_SUCCESS',
  VIEW_SM_ERROR: 'VIEW_SM_ERROR',
};
export const VIEW_MPO_CONSTANT = {
  VIEW_MPO_LOADING: 'VIEW_MPO_LOADING',
  VIEW_MPO_SUCCESS: 'VIEW_MPO_SUCCESS',
  VIEW_MPO_ERROR: 'VIEW_MPO_ERROR',
};

export const CREATE_ADMIN_CONSTANT = {
  CREATE_ADMIN_LOADING: 'CREATE_ADMIN_LOADING',
  CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_ERROR: 'CREATE_ADMIN_ERROR',
};
export const CREATE_DIRECTOR_CONSTANT = {
  CREATE_DIRECTOR_LOADING: 'CREATE_DIRECTOR_LOADING',
  CREATE_DIRECTOR_SUCCESS: 'CREATE_DIRECTOR_SUCCESS',
  CREATE_DIRECTOR_ERROR: 'CREATE_DIRECTOR_ERROR',
};

export const CREATE_DEPO_CONSTANT = {
  CREATE_DEPO_LOADING: 'CREATE_DEPO_LOADING',
  CREATE_DEPO_SUCCESS: 'CREATE_DEPO_SUCCESS',
  CREATE_DEPO_ERROR: 'CREATE_DEPO_ERROR',
};
export const CREATE_DELIVERYSTAFF_CONSTANT = {
  CREATE_DELIVERYSTAFF_LOADING: 'CREATE_DELIVERYSTAFF_LOADING',
  CREATE_DELIVERYSTAFF_SUCCESS: 'CREATE_DELIVERYSTAFF_SUCCESS',
  CREATE_DELIVERYSTAFF_ERROR: 'CREATE_DELIVERYSTAFF_ERROR',
};
export const CREATE_AM_CONSTANT = {
  CREATE_AM_LOADING: 'CREATE_AM_LOADING',
  CREATE_AM_SUCCESS: 'CREATE_AM_SUCCESS',
  CREATE_AM_ERROR: 'CREATE_AM_ERROR',
};
export const CREATE_RSM_CONSTANT = {
  CREATE_RSM_LOADING: 'CREATE_RSM_LOADING',
  CREATE_RSM_SUCCESS: 'CREATE_RSM_SUCCESS',
  CREATE_RSM_ERROR: 'CREATE_RSM_ERROR',
};
export const CREATE_SM_CONSTANT = {
  CREATE_SM_LOADING: 'CREATE_SM_LOADING',
  CREATE_SM_SUCCESS: 'CREATE_SM_SUCCESS',
  CREATE_SM_ERROR: 'CREATE_SM_ERROR',
};
export const CREATE_MPO_CONSTANT = {
  CREATE_MPO_LOADING: 'CREATE_MPO_LOADING',
  CREATE_MPO_SUCCESS: 'CREATE_MPO_SUCCESS',
  CREATE_MPO_ERROR: 'CREATE_MPO_ERROR',
};
export const UPDATE_ADMIN_CONSTANT = {
  UPDATE_ADMIN_LOADING: 'UPDATE_ADMIN_LOADING',
  UPDATE_ADMIN_SUCCESS: 'UPDATE_ADMIN_SUCCESS',
  UPDATE_ADMIN_ERROR: 'UPDATE_ADMIN_ERROR',
};
export const UPDATE_DIRECTOR_CONSTANT = {
  UPDATE_DIRECTOR_LOADING: 'UPDATE_DIRECTOR_LOADING',
  UPDATE_DIRECTOR_SUCCESS: 'UPDATE_DIRECTOR_SUCCESS',
  UPDATE_DIRECTOR_ERROR: 'UPDATE_DIRECTOR_ERROR',
};

export const UPDATE_DEPO_CONSTANT = {
  UPDATE_DEPO_LOADING: 'UPDATE_DEPO_LOADING',
  UPDATE_DEPO_SUCCESS: 'UPDATE_DEPO_SUCCESS',
  UPDATE_DEPO_ERROR: 'UPDATE_DEPO_ERROR',
};
export const UPDATE_DELIVERYSTAFF_CONSTANT = {
  UPDATE_DELIVERYSTAFF_LOADING: 'UPDATE_DELIVERYSTAFF_LOADING',
  UPDATE_DELIVERYSTAFF_SUCCESS: 'UPDATE_DELIVERYSTAFF_SUCCESS',
  UPDATE_DELIVERYSTAFF_ERROR: 'UPDATE_DELIVERYSTAFF_ERROR',
};
export const UPDATE_AM_CONSTANT = {
  UPDATE_AM_LOADING: 'UPDATE_AM_LOADING',
  UPDATE_AM_SUCCESS: 'UPDATE_AM_SUCCESS',
  UPDATE_AM_ERROR: 'UPDATE_AM_ERROR',
};
export const UPDATE_RSM_CONSTANT = {
  UPDATE_RSM_LOADING: 'UPDATE_RSM_LOADING',
  UPDATE_RSM_SUCCESS: 'UPDATE_RSM_SUCCESS',
  UPDATE_RSM_ERROR: 'UPDATE_RSM_ERROR',
};
export const UPDATE_SM_CONSTANT = {
  UPDATE_SM_LOADING: 'UPDATE_SM_LOADING',
  UPDATE_SM_SUCCESS: 'UPDATE_SM_SUCCESS',
  UPDATE_SM_ERROR: 'UPDATE_SM_ERROR',
};
export const UPDATE_MPO_CONSTANT = {
  UPDATE_MPO_LOADING: 'UPDATE_MPO_LOADING',
  UPDATE_MPO_SUCCESS: 'UPDATE_MPO_SUCCESS',
  UPDATE_MPO_ERROR: 'UPDATE_MPO_ERROR',
};

export const VIEW_TARGET_CONSTANT = {
  VIEW_TARGET_LOADING: 'VIEW_TARGET_LOADING',
  VIEW_TARGET_SUCCESS: 'VIEW_TARGET_SUCCESS',
  VIEW_TARGET_ERROR: 'VIEW_TARGET_ERROR',
};
export const CREATE_TARGET_CONSTANT = {
  CREATE_TARGET_LOADING: 'CREATE_TARGET_LOADING',
  CREATE_TARGET_SUCCESS: 'CREATE_TARGET_SUCCESS',
  CREATE_TARGET_ERROR: 'CREATE_TARGET_ERROR',
};
export const EDIT_TARGET_CONSTANT = {
  EDIT_TARGET_LOADING: 'EDIT_TARGET_LOADING',
  EDIT_TARGET_SUCCESS: 'EDIT_TARGET_SUCCESS',
  EDIT_TARGET_ERROR: 'EDIT_TARGET_ERROR',
};


export const SUSPAND_TARGET_CONSTANT = {
  SUSPAND_TARGET_LOADING: 'SUSPAND_TARGET_LOADING',
  SUSPAND_TARGET_SUCCESS: 'SUSPAND_TARGET_SUCCESS',
  SUSPAND_TARGET_ERROR: 'SUSPAND_TARGET_ERROR',
};

export const VIEW_DCP_CONSTANT = {
  VIEW_DCP_LOADING: 'VIEW_DCP_LOADING',
  VIEW_DCP_SUCCESS: 'VIEW_DCP_SUCCESS',
  VIEW_DCP_ERROR: 'VIEW_DCP_ERROR',
};
export const VIEW_DCR_CONSTANT = {
  VIEW_DCR_LOADING: 'VIEW_DCR_LOADING',
  VIEW_DCR_SUCCESS: 'VIEW_DCR_SUCCESS',
  VIEW_DCR_ERROR: 'VIEW_DCR_ERROR',
};
export const CREATE_DCP_CONSTANT = {
  CREATE_DCP_LOADING: 'CREATE_DCP_LOADING',
  CREATE_DCP_SUCCESS: 'CREATE_DCP_SUCCESS',
  CREATE_DCP_ERROR: 'CREATE_DCP_ERROR',
};

export const VIEW_SCHEDULE_CONSTANT = {
  VIEW_SCHEDULE_LOADING: 'VIEW_SCHEDULE_LOADING',
  VIEW_SCHEDULE_SUCCESS: 'VIEW_SCHEDULE_SUCCESS',
  VIEW_SCHEDULE_ERROR: 'VIEW_SCHEDULE_ERROR',
};

export const SUSPAND_DCP_DCR_CONSTANT = {
  SUSPAND_DCP_DCR_LOADING: 'SUSPAND_DCP_DCR_LOADING',
  SUSPAND_DCP_DCR_SUCCESS: 'SUSPAND_DCP_DCR_SUCCESS',
  SUSPAND_DCP_DCR_ERROR: 'SUSPAND_DCP_DCR_ERROR',
};

export const SUSPAND_SCHEDULE_CONSTANT = {
  SUSPAND_SCHEDULE_LOADING: 'SUSPAND_SCHEDULE_LOADING',
  SUSPAND_SCHEDULE_SUCCESS: 'SUSPAND_SCHEDULE_SUCCESS',
  SUSPAND_SCHEDULE_ERROR: 'SUSPAND_SCHEDULE_ERROR',
};

export const EDIT_SCHEDULE_CONSTANT = {
  EDIT_SCHEDULE_LOADING: 'EDIT_SCHEDULE_LOADING',
  EDIT_SCHEDULE_SUCCESS: 'EDIT_SCHEDULE_SUCCESS',
  EDIT_SCHEDULE_ERROR: 'EDIT_SCHEDULE_ERROR',
};

export const VIEW_ATTENDANCE_CONSTANT = {
  VIEW_ATTENDANCE_LOADING: 'VIEW_ATTENDANCE_LOADING',
  VIEW_ATTENDANCE_SUCCESS: 'VIEW_ATTENDANCE_SUCCESS',
  VIEW_ATTENDANCE_ERROR: 'VIEW_ATTENDANCE_ERROR',
};

export const CREATE_ATTENDANCE_CONSTANT = {
  CREATE_ATTENDANCE_LOADING: 'CREATE_ATTENDANCE_LOADING',
  CREATE_ATTENDANCE_SUCCESS: 'CREATE_ATTENDANCE_SUCCESS',
  CREATE_ATTENDANCE_ERROR: 'CREATE_ATTENDANCE_ERROR',
};

export const SUSPAND_ATTENDANCE_CONSTANT = {
  SUSPAND_ATTENDANCE_LOADING: 'SUSPAND_ATTENDANCE_LOADING',
  SUSPAND_ATTENDANCE_SUCCESS: 'SUSPAND_ATTENDANCE_SUCCESS',
  SUSPAND_ATTENDANCE_ERROR: 'SUSPAND_ATTENDANCE_ERROR',
};

export const VIEW_USER_CONSTANT = {
  GET_SM: 'GET_SM',
  GET_RSM: 'GET_RSM',
  GET_AM: 'GET_AM',
  GET_MPO: 'GET_MPO',
};

export const VIEW_CATEGORY_CONSTANT = {
  VIEW_CATEGORY_LOADING: 'VIEW_CATEGORY_LOADING',
  VIEW_CATEGORY_SUCCESS: 'VIEW_CATEGORY_SUCCESS',
  VIEW_CATEGORY_ERROR: 'VIEW_CATEGORY_ERROR'
}


export const VIEW_STATIC_CONSTANT = {
  VIEW_STATIC_LOADING: 'VIEW_STATIC_LOADING',
  VIEW_STATIC_SUCCESS: 'VIEW_STATIC_SUCCESS',
  VIEW_STATIC_ERROR: 'VIEW_STATIC_ERROR'
}

export const CREATE_CATEGORY__CONSTANT = {
  CREATE_CATEGORY_LOADING: 'CREATE_CATEGORY_LOADING',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_ERROR: 'CREATE_CATEGORY_ERROR'
}
export const UPDATE_CATEGORY__CONSTANT = {
  UPDATE_CATEGORY_LOADING: 'UPDATE_CATEGORY_LOADING',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR'
}

export const VIEW_PERORITY_CONSTANT = {
  VIEW_PERORITY_LOADING: 'VIEW_PERORITY_LOADING',
  VIEW_PERORITY_SUCCESS: 'VIEW_PERORITY_SUCCESS',
  VIEW_PERORITY_ERROR: 'VIEW_PERORITY_ERROR'
}
export const VIEW_DOCTOR_PERORITY_CONSTANT = {
  VIEW_DOCTOR_PERORITY_LOADING: 'VIEW_DOCTOR_PERORITY_LOADING',
  VIEW_DOCTOR_PERORITY_SUCCESS: 'VIEW_DOCTOR_PERORITY_SUCCESS',
  VIEW_DOCTOR_PERORITY_ERROR: 'VIEW_DOCTOR_PERORITY_ERROR'
}
export const CREATE_PERORITY_CONSTANT = {
  CREATE_PERORITY_LOADING: 'CREATE_PERORITY_LOADING',
  CREATE_PERORITY_SUCCESS: 'CREATE_PERORITY_SUCCESS',
  CREATE_PERORITY_ERROR: 'CREATE_PERORITY_ERROR'
}

export const UPDATE_PERORITY_CONSTANT = {
  UPDATE_PERORITY_LOADING: 'UPDATE_PERORITY_LOADING',
  UPDATE_PERORITY_SUCCESS: 'UPDATE_PERORITY_SUCCESS',
  UPDATE_PERORITY_ERROR: 'UPDATE_PERORITY_ERROR'
}
export const SUSPAND_PERORITY_CONSTANT = {
  SUSPAND_PERORITY_LOADING: 'SUSPAND_PERORITY_LOADING',
  SUSPAND_PERORITY_SUCCESS: 'SUSPAND_PERORITY_SUCCESS',
  SUSPAND_PERORITY_ERROR: 'SUSPAND_PERORITY_ERROR'
}


export const CREATE_SAMPLE_CONSTANT = {
  CREATE_SAMPLE_LOADING: 'CREATE_SAMPLE_LOADING',
  CREATE_SAMPLE_SUCCESS: 'CREATE_SAMPLE_SUCCESS',
  CREATE_SAMPLE_ERROR: 'CREATE_SAMPLE_ERROR'
}

export const UPDATE_SAMPLE_CONSTANT = {
  UPDATE_SAMPLE_LOADING: 'UPDATE_SAMPLE_LOADING',
  UPDATE_SAMPLE_SUCCESS: 'UPDATE_SAMPLE_SUCCESS',
  UPDATE_SAMPLE_ERROR: 'UPDATE_SAMPLE_ERROR'
}
export const SUSPAND_SAMPLE_CONSTANT = {
  SUSPAND_SAMPLE_LOADING: 'SUSPAND_SAMPLE_LOADING',
  SUSPAND_SAMPLE_SUCCESS: 'SUSPAND_SAMPLE_SUCCESS',
  SUSPAND_SAMPLE_ERROR: 'SUSPAND_SAMPLE_ERROR'
}
export const VIEW_SAMPLE_CONSTANT = {
  VIEW_SAMPLE_LOADING: 'VIEW_SAMPLE_LOADING',
  VIEW_SAMPLE_SUCCESS: 'VIEW_SAMPLE_SUCCESS',
  VIEW_SAMPLE_ERROR: 'VIEW_SAMPLE_ERROR'
}

export const VIEW_SAMPLE_TRANSACTION_CONSTANT = {
  VIEW_SAMPLE_TRANSACTION_LOADING: 'VIEW_SAMPLE_TRANSACTION_LOADING',
  VIEW_SAMPLE_TRANSACTION_SUCCESS: 'VIEW_SAMPLE_TRANSACTION_SUCCESS',
  VIEW_SAMPLE_TRANSACTION_ERROR: 'VIEW_SAMPLE_TRANSACTION_ERROR'
}

export const CREATE_SAMPLE_TRANSACTION_CONSTANT = {
  CREATE_SAMPLE_TRANSACTION_LOADING: 'CREATE_SAMPLE_TRANSACTION_LOADING',
  CREATE_SAMPLE_TRANSACTION_SUCCESS: 'CREATE_SAMPLE_TRANSACTION_SUCCESS',
  CREATE_SAMPLE_TRANSACTION_ERROR: 'CREATE_SAMPLE_TRANSACTION_ERROR'
}

export const SUSPAND_SAMPLE_TRANSACTION_CONSTANT = {
  SUSPAND_SAMPLE_TRANSACTION_LOADING: 'SUSPAND_SAMPLE_TRANSACTION_LOADING',
  SUSPAND_SAMPLE_TRANSACTION_SUCCESS: 'SUSPAND_SAMPLE_TRANSACTION_SUCCESS',
  SUSPAND_SAMPLE_TRANSACTION_ERROR: 'SUSPAND_SAMPLE_TRANSACTION_ERROR'
}

export const SET_USER_FROM_LOCAL = 'SET_USER_FROM_LOCAL';


export const VIEW_RESCHEDULE_REQUEST = {
  VIEW_RESCHEDULE_REQUEST_LOADING: 'VIEW_RESCHEDULE_REQUEST_LOADING',
  VIEW_RESCHEDULE_REQUEST_SUCESS: 'VIEW_RESCHEDULE_REQUEST_SUCESS',
  VIEW_RESCHEDULE_REQUEST_ERROR: 'VIEW_RESCHEDULE_REQUEST_ERROR',
};
export const UPDATE_RESCHEDULE_REQUEST = {
  UPDATE_RESCHEDULE_REQUEST_LOADING: 'UPDATE_RESCHEDULE_REQUEST_LOADING',
  UPDATE_RESCHEDULE_REQUEST_SUCESS: 'UPDATE_RESCHEDULE_REQUEST_SUCESS',
  UPDATE_RESCHEDULE_REQUEST_ERROR: 'UPDATE_RESCHEDULE_REQUEST_ERROR',
};
export const GET_AVILABLE_SLOTS = {
  GET_AVILABLE_SLOTS_LOADING: 'GET_AVILABLE_SLOTS_LOADING',
  GET_AVILABLE_SLOTS_SUCESS: 'GET_AVILABLE_SLOTS_SUCESS',
  GET_AVILABLE_SLOTS_ERROR: 'GET_AVILABLE_SLOTS_ERROR',
};
export const ASSIGN_ORDER_CONSTANT = {
  ASSIGN_ORDER_LOADING: 'ASSIGN_ORDER_LOADING',
  ASSIGN_ORDER_SUCESS: 'ASSIGN_ORDER_SUCESS',
  ASSIGN_ORDER_ERROR: 'ASSIGN_ORDER_ERROR'
}

export const VIEW_CURRENT_ORDER_CONSTANTS = {
  VIEW_CURRENT_ORDER_LOADING: 'VIEW_CURRENT_ORDER_LOADING',
  VIEW_CURRENT_ORDER_SUCESS: 'VIEW_CURRENT_ORDER_SUCESS',
  VIEW_CURRENT_ORDER_ERROR: 'VIEW_CURRENT_ORDER_ERROR',
};
export const VIEW_REMOVAL_REQUEST_CONSTANTS = {
  VIEW_REMOVAL_REQUEST_LOADING: 'VIEW_REMOVAL_REQUEST_LOADING',
  VIEW_REMOVAL_REQUEST_SUCESS: 'VIEW_REMOVAL_REQUEST_SUCESS',
  VIEW_REMOVAL_REQUEST_ERROR: 'VIEW_REMOVAL_REQUEST_ERROR',
};
export const ACCEPT_REMOVAL_REQUEST_CONSTANTS = {
  ACCEPT_REMOVAL_REQUEST_LOADING: 'ACCEPT_REMOVAL_REQUEST_LOADING',
  ACCEPT_REMOVAL_REQUEST_SUCESS: 'ACCEPT_REMOVAL_REQUEST_SUCESS',
  ACCEPT_REMOVAL_REQUEST_ERROR: 'ACCEPT_REMOVAL_REQUEST_ERROR',
};
export const REJECT_REMOVAL_REQUEST_CONSTANTS = {
  REJECT_REMOVAL_REQUEST_LOADING: 'REJECT_REMOVAL_REQUEST_LOADING',
  REJECT_REMOVAL_REQUEST_SUCESS: 'REJECT_REMOVAL_REQUEST_SUCESS',
  REJECT_REMOVAL_REQUEST_ERROR: 'REJECT_REMOVAL_REQUEST_ERROR',
};
export const VIEW_CURRENT_REMOVAL_REQUEST_CONSTANTS = {
  VIEW_CURRENT_REMOVAL_REQUEST_LOADING: 'VIEW_CURRENT_REMOVAL_REQUEST_LOADING',
  VIEW_CURRENT_REMOVAL_REQUEST_SUCESS: 'VIEW_CURRENT_REMOVAL_REQUEST_SUCESS',
  VIEW_CURRENT_REMOVAL_REQUEST_ERROR: 'VIEW_CURRENT_REMOVAL_REQUEST_ERROR',
};
export const VIEW_CURRENT_APPOINMTENT_REQUEST_CONSTANTS = {
  VIEW_CURRENT_APPOINMTENT_REQUEST_LOADING:
    'VIEW_CURRENT_APPOINMTENT_REQUEST_LOADING',
  VIEW_CURRENT_APPOINMTENT_REQUEST_SUCESS:
    'VIEW_CURRENT_APPOINMTENT_REQUEST_SUCESS',
  VIEW_CURRENT_APPOINMTENT_REQUEST_ERROR:
    'VIEW_CURRENT_APPOINMTENT_REQUEST_ERROR',
};
export const CANCAL_APPOINTMENT_REQEUEST = {
  CANCAL_APPOINTMENT_REQEUEST_LOADING: 'CANCAL_APPOINTMENT_REQEUEST_LOADING',
  CANCAL_APPOINTMENT_REQEUEST_SUCESS: 'CANCAL_APPOINTMENT_REQEUEST_SUCESS',
  CANCAL_APPOINTMENT_REQEUEST_ERROR: 'CANCAL_APPOINTMENT_REQEUEST_ERROR',
};
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHANGE_PASSWORD_CONSTANT = {
  CHANGE_PASSWORD_LOADING: 'CHANGE_PASSWORD_LOADING',
  CHANGE_PASSWORD_SUCESS: 'CHANGE_PASSWORD_SUCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
};
export const CREATE_DOCTOR_CONSTANT = {
  CREATE_DOCTOR_LOADING: 'CREATE_DOCTOR_LOADING',
  CREATE_DOCTOR_SUCESS: 'CREATE_DOCTOR_SUCESS',
  CREATE_DOCTOR_ERROR: 'CREATE_DOCTOR_ERROR',
};
export const VIEW_DOCTOR_CONSTANT = {
  VIEW_DOCTOR_LOADING: 'VIEW_DOCTOR_LOADING',
  VIEW_DOCTOR_SUCESS: 'VIEW_DOCTOR_SUCESS',
  VIEW_DOCTOR_ERROR: 'VIEW_DOCTOR_ERROR',
};
export const VIEW_CURRENT_DOCTOR_CONSTANT = {
  VIEW_CURRENT_DOCTOR_LOADING: 'VIEW_CURRENT_DOCTOR_LOADING',
  VIEW_CURRENT_DOCTOR_SUCESS: 'VIEW_CURRENT_DOCTOR_SUCESS',
  VIEW_CURRENT_DOCTOR_ERROR: 'VIEW_CURRENT_DOCTOR_ERROR',
};
export const UPDATE_DOCTOR_CONSTANT = {
  UPDATE_DOCTOR_LOADING: 'UPDATE_DOCTOR_LOADING',
  UPDATE_DOCTOR_SUCESS: 'UPDATE_DOCTOR_SUCESS',
  UPDATE_DOCTOR_ERROR: 'UPDATE_DOCTOR_ERROR',
};
export const CREATE_ADMINISTRATOR_CONSTANT = {
  CREATE_ADMINISTRATOR_LOADING: 'CREATE_ADMINISTRATOR_LOADING',
  CREATE_ADMINISTRATOR_SUCESS: 'CREATE_ADMINISTRATOR_SUCESS',
  CREATE_ADMINISTRATOR_ERROR: 'CREATE_ADMINISTRATOR_ERROR',
};
export const UPDATE_ADMINISTRATOR_CONSTANT = {
  UPDATE_ADMINISTRATOR_LOADING: 'UPDATE_ADMINISTRATOR_LOADING',
  UPDATE_ADMINISTRATOR_SUCESS: 'UPDATE_ADMINISTRATOR_SUCESS',
  UPDATE_ADMINISTRATOR_ERROR: 'UPDATE_ADMINISTRATOR_ERROR',
};
export const VIEW_ADMINISTRATOR_CONSTANT = {
  VIEW_ADMINISTRATOR_LOADING: 'VIEW_ADMINISTRATOR_LOADING',
  VIEW_ADMINISTRATOR_SUCESS: 'VIEW_ADMINISTRATOR_SUCESS',
  VIEW_ADMINISTRATOR_ERROR: 'VIEW_ADMINISTRATOR_ERROR',
};
export const VIEW_CURRENT_ADMINISTRATOR_CONSTANT = {
  VIEW_CURRENT_ADMINISTRATOR_LOADING: 'VIEW_CURRENT_ADMINISTRATOR_LOADING',
  VIEW_CURRENT_ADMINISTRATOR_SUCESS: 'VIEW_CURRENT_ADMINISTRATOR_SUCESS',
  VIEW_CURRENT_ADMINISTRATOR_ERROR: 'VIEW_CURRENT_ADMINISTRATOR_ERROR',
};

export const CREATE_LABADMIN_CONSTANT = {
  CREATE_LABADMIN_LOADING: 'CREATE_LABADMIN_LOADING',
  CREATE_LABADMIN_SUCESS: 'CREATE_LABADMIN_SUCESS',
  CREATE_LABADMIN_ERROR: 'CREATE_LABADMIN_ERROR',
};
export const UPDATE_LABADMIN_CONSTANT = {
  UPDATE_LABADMIN_LOADING: 'UPDATE_LABADMIN_LOADING',
  UPDATE_LABADMIN_SUCESS: 'UPDATE_LABADMIN_SUCESS',
  UPDATE_LABADMIN_ERROR: 'UPDATE_LABADMIN_ERROR',
};

export const VIEW_LABADMIN_CONSTANT = {
  VIEW_LABADMIN_LOADING: 'VIEW_LABADMIN_LOADING',
  VIEW_LABADMIN_SUCESS: 'VIEW_LABADMIN_SUCESS',
  VIEW_LABADMIN_ERROR: 'VIEW_LABADMIN_ERROR',
};
export const VIEW_CURRENT_LABADMIN_CONSTANT = {
  VIEW_CURRENT_LABADMIN_LOADING: 'VIEW_CURRENT_LABADMIN_LOADING',
  VIEW_CURRENT_LABADMIN_SUCESS: 'VIEW_CURRENT_LABADMIN_SUCESS',
  VIEW_CURRENT_LABADMIN_ERROR: 'VIEW_CURRENT_LABADMIN_ERROR',
};

export const CREATE_PHARMACY_CONSTANT = {
  CREATE_PHARMACY_LOADING: 'CREATE_PHARMACY_LOADING',
  CREATE_PHARMACY_SUCESS: 'CREATE_PHARMACY_SUCESS',
  CREATE_PHARMACY_ERROR: 'CREATE_PHARMACY_ERROR',
};
export const UPDATE_PHARMACY_CONSTANT = {
  UPDATE_PHARMACY_LOADING: 'UPDATE_PHARMACY_LOADING',
  UPDATE_PHARMACY_SUCESS: 'UPDATE_PHARMACY_SUCESS',
  UPDATE_PHARMACY_ERROR: 'UPDATE_PHARMACY_ERROR',
};

export const VIEW_PHARMACY_CONSTANT = {
  VIEW_PHARMACY_LOADING: 'VIEW_PHARMACY_LOADING',
  VIEW_PHARMACY_SUCESS: 'VIEW_PHARMACY_SUCESS',
  VIEW_PHARMACY_ERROR: 'VIEW_PHARMACY_ERROR',
};
export const VIEW_CURRENT_PHARMACY_CONSTANT = {
  VIEW_CURRENT_PHARMACY_LOADING: 'VIEW_CURRENT_PHARMACY_LOADING',
  VIEW_CURRENT_PHARMACY_SUCESS: 'VIEW_CURRENT_PHARMACY_SUCESS',
  VIEW_CURRENT_PHARMACY_ERROR: 'VIEW_CURRENT_PHARMACY_ERROR',
};

export const CREATE_SUPERADMIN_CONSTANT = {
  CREATE_SUPERADMIN_LOADING: 'CREATE_SUPERADMIN_LOADING',
  CREATE_SUPERADMIN_SUCESS: 'CREATE_SUPERADMIN_SUCESS',
  CREATE_SUPERADMIN_ERROR: 'CREATE_SUPERADMIN_ERROR',
};
export const UPDATE_SUPERADMIN_CONSTANT = {
  UPDATE_SUPERADMIN_LOADING: 'UPDATE_SUPERADMIN_LOADING',
  UPDATE_SUPERADMIN_SUCESS: 'UPDATE_SUPERADMIN_SUCESS',
  UPDATE_SUPERADMIN_ERROR: 'UPDATE_SUPERADMIN_ERROR',
};

export const VIEW_SUPERADMIN_CONSTANT = {
  VIEW_SUPERADMIN_LOADING: 'VIEW_SUPERADMIN_LOADING',
  VIEW_SUPERADMIN_SUCESS: 'VIEW_SUPERADMIN_SUCESS',
  VIEW_SUPERADMIN_ERROR: 'VIEW_SUPERADMIN_ERROR',
};
export const VIEW_CURRENT_SUPERADMIN_CONSTANT = {
  VIEW_CURRENT_SUPERADMIN_LOADING: 'VIEW_CURRENT_SUPERADMIN_LOADING',
  VIEW_CURRENT_SUPERADMIN_SUCESS: 'VIEW_CURRENT_SUPERADMIN_SUCESS',
  VIEW_CURRENT_SUPERADMIN_ERROR: 'VIEW_CURRENT_SUPERADMIN_ERROR',
};

export const GET_DEPARTMENT_CONSTANT = {
  GET_DEPARTMENT_LOADING: 'GET_DEPARTMENT_LOADING',
  GET_DEPARTMENT_SUCESS: 'GET_DEPARTMENT_SUCESS',
  GET_DEPARTMENT_ERROR: 'GET_DEPARTMENT_ERROR',
};

export const CREATE_PATIENT_CONSTANT = {
  CREATE_PATIENT_LOADING: 'CREATE_PATIENT_LOADING',
  CREATE_PATIENT_SUCESS: 'CREATE_PATIENT_SUCESS',
  CREATE_PATIENT_ERROR: 'CREATE_PATIENT_ERROR',
};
export const UPDATE_PATIENT_CONSTANT = {
  UPDATE_PATIENT_LOADING: 'UPDATE_PATIENT_LOADING',
  UPDATE_PATIENT_SUCESS: 'UPDATE_PATIENT_SUCESS',
  UPDATE_PATIENT_ERROR: 'UPDATE_PATIENT_ERROR',
};
export const VIEW_PATIENT_CONSTANT = {
  VIEW_PATIENT_LOADING: 'VIEW_PATIENT_LOADING',
  VIEW_PATIENT_SUCESS: 'VIEW_PATIENT_SUCESS',
  VIEW_PATIENT_ERROR: 'VIEW_PATIENT_ERROR',
};
export const VIEW_CURRENT_PATIENT_CONSTANT = {
  VIEW_CURRENT_PATIENT_LOADING: 'VIEW_CURRENT_PATIENT_LOADING',
  VIEW_CURRENT_PATIENT_SUCESS: 'VIEW_CURRENT_PATIENT_SUCESS',
  VIEW_CURRENT_PATIENT_ERROR: 'VIEW_CURRENT_PATIENT_ERROR',
};

export const UPDATE_ORDER_CONSTANT = {
  UPDATE_ORDER_LOADING: 'UPDATE_ORDER_LOADING',
  UPDATE_ORDER_SUCESS: 'UPDATE_ORDER_SUCESS',
  UPDATE_ORDER_ERROR: 'UPDATE_ORDER_ERROR',
};

export const VIEW_TEST_CONSTANT = {
  VIEW_TEST_LOADING: 'VIEW_TEST_LOADING',
  VIEW_TEST_SUCESS: 'VIEW_TEST_SUCESS',
  VIEW_TEST_ERROR: 'VIEW_TEST_ERROR',
};

export const UPDATE_TEST_CONSTANT = {
  UPDATE_TEST_LOADING: 'UPDATE_TEST_LOADING',
  UPDATE_TEST_SUCESS: 'UPDATE_TEST_SUCESS',
  UPDATE_TEST_ERROR: 'UPDATE_TEST_ERROR',
};

export const UPDATE_CATEGORY_CONSTANT = {
  UPDATE_CATEGORY_LOADING: 'UPDATE_CATEGORY_LOADING',
  UPDATE_CATEGORY_SUCESS: 'UPDATE_CATEGORY_SUCESS',
  UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',
};
export const READR_CATEGORY_CONSTANT = {
  READR_CATEGORY_LOADING: 'READR_CATEGORY_LOADING',
  READR_CATEGORY_SUCESS: 'READR_CATEGORY_SUCESS',
  READR_CATEGORY_ERROR: 'READR_CATEGORY_ERROR',
};
export const CREATE_TEST_CONSTANT = {
  CREATE_TEST_LOADING: 'CREATE_TEST_LOADING',
  CREATE_TEST_SUCESS: 'CREATE_TEST_SUCESS',
  CREATE_TEST_ERROR: 'CREATE_TEST_ERROR',
};
export const CREATE_CATEGORY_CONSTANT = {
  CREATE_CATEGORY_LOADING: 'CREATE_CATEGORY_LOADING',
  CREATE_CATEGORY_SUCESS: 'CREATE_CATEGORY_SUCESS',
  CREATE_CATEGORY_ERROR: 'CREATE_CATEGORY_ERROR',
};

export const LOADER_CONSTANT = {
  SM_LOADING: 'SM_LOADING',
  RSM_LOADING: 'RSM_LOADING',
  AM_LOADING: 'AM_LOADING',
  MPO_LOADING: 'MPO_LOADING',
}

export const VIEW_MEDICINE_CONSTANT = {
  VIEW_MEDICINES_LOADING: 'VIEW_MEDICINES_LOADING',
  VIEW_MEDICINES_SUCESS: 'VIEW_MEDICINES_SUCESS',
  VIEW_MEDICINES_ERROR: 'VIEW_MEDICINES_ERROR',
};
export const CREATE_MEDICINE_CONSTANT = {
  CREATE_MEDICINES_LOADING: 'CREATE_MEDICINES_LOADING',
  CREATE_MEDICINES_SUCESS: 'CREATE_MEDICINES_SUCESS',
  CREATE_MEDICINES_ERROR: 'CREATE_MEDICINES_ERROR',
};
export const UPDATE_MEDICINE_CONSTANT = {
  UPDATE_MEDICINES_LOADING: 'UPDATE_MEDICINES_LOADING',
  UPDATE_MEDICINES_SUCESS: 'UPDATE_MEDICINES_SUCESS',
  UPDATE_MEDICINES_ERROR: 'UPDATE_MEDICINES_ERROR',
};

export const VIEW_REPORT_CONSTANT = {
  VIEW_REPORT_LOADING: 'VIEW_REPORT_LOADING',
  VIEW_REPORT_SUCCESS: 'VIEW_REPORT_SUCCESS',
  VIEW_REPORT_ERROR: 'VIEW_REPORT_ERROR',
};

export const REGION_CLASSIFICAION_CONSTANT = {
  CREATE_REGION_CLASSIFICAION_LOADING: 'CREATE_REGION_CLASSIFICAION_LOADING',
  CREATE_REGION_CLASSIFICAION_SUCCESS: 'CREATE_REGION_CLASSIFICAION_SUCCESS',
  CREATE_REGION_CLASSIFICAION_ERROR: 'CREATE_REGION_CLASSIFICAION_ERROR',
};
export const READ_REGION_CONSTANT = {
  READ_REGION_LOADING: 'READ_REGION_LOADING',
  READ_REGION_SUCCESS: 'READ_REGION_SUCCESS',
  READ_REGION_ERROR: 'READ_REGION_ERROR',
};
export const UPDATE_REGION_CLASSIFICAION_CONSTANT = {
  UPDATE_REGION_CLASSIFICAION_LOADING: 'UPDATE_REGION_CLASSIFICAION_LOADING',
  UPDATE_REGION_CLASSIFICAION_SUCCESS: 'UPDATE_REGION_CLASSIFICAION_SUCCESS',
  UPDATE_REGION_CLASSIFICAION_ERROR: 'UPDATE_REGION_CLASSIFICAION_ERROR'
}
export const EDIT_REGION_CLASSIFICATION = {
  EDIT_REGION_CLASSIFICATION_LOADING: 'EDIT_REGION_CLASSIFICATION_LOADING',
  EDIT_REGION_CLASSIFICATION_SUCCESS: 'EDIT_REGION_CLASSIFICATION_SUCCESS',
  EDIT_REGION_CLASSIFICATION_ERROR: 'EDIT_REGION_CLASSIFICATION_ERROR',
};

export const VIEW_ORDER_CONSTANTS = {
  VIEW_ORDER_LOADING: 'VIEW_ORDER_LOADING',
  VIEW_ORDER_SUCESS: 'VIEW_ORDER_SUCESS',
  VIEW_ORDER_ERROR: 'VIEW_ORDER_ERROR',
};

// Concord Order
export const ORDER_CONSTANTS = {
  ORDER_LOADING: 'ORDER_LOADING',
  ORDER_SUCESS: 'ORDER_SUCESS',
  ORDER_ERROR: 'ORDER_ERROR',
  ORDER_GET_USER: 'ORDER_GET_USER',
  ORDER_GET_USER_LOADER: 'ORDER_GET_USER_LOADER',
  ORDER_GET_USER_RSM: 'ORDER_GET_USER_RSM',
  ORDER_GET_USER_RSM_LOADER: 'ORDER_GET_USER_RSM_LOADER',
  ORDER_GET_USER_AM: 'ORDER_GET_USER_AM',
  ORDER_GET_USER_AM_LOADER: 'ORDER_GET_USER_AM_LOADER',
  ORDER_GET_USER_MPO: 'ORDER_GET_USER_MPO',
  ORDER_GET_USER_MPO_LOADER: 'ORDER_GET_USER_MPO_LOADER',
  ORDER_GET_CUSTOMER: 'ORDER_GET_CUSTOMER',
  ORDER_GET_CUSTOMER_LOADER: 'ORDER_GET_CUSTOMER_LOADER',
  ORDER_GET_STOCK_MEDICINE: 'ORDER_GET_STOCK_MEDICINE',
  STATIC_DATA: 'STATIC_DATA',
  CREATE_ORDER_LOADING: 'CREATE_ORDER_LOADING',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_ERROR: 'CREATE_ORDER_ERROR',
  ORDER_LOADING_All: 'ORDER_LOADING_All',
};

// Concord Department-Head
export const DEPARTMENT_HEAD_CONSTANT = {
  DEPARTMENT_HEAD_LOADING: 'DEPARTMENT_HEAD_LOADING',
  DEPARTMENT_HEAD_SUCESS: 'DEPARTMENT_HEAD_SUCESS',
  DEPARTMENT_HEAD_ERROR: 'DEPARTMENT_HEAD_ERROR',

  CREATE_DEPARTMENT_HEAD_LOADING: 'CREATE_DEPARTMENT_HEAD_LOADING',
  CREATE_DEPARTMENT_HEAD_SUCCESS: 'CREATE_DEPARTMENT_HEAD_SUCCESS',
  CREATE_DEPARTMENT_HEAD_ERROR: 'CREATE_DEPARTMENT_HEAD_ERROR',

  UPDATE_DEPARTMENT_HEAD_LOADING: 'UPDATE_DEPARTMENT_HEAD_LOADING',
  UPDATE_DEPARTMENT_HEAD_SUCCESS: 'UPDATE_DEPARTMENT_HEAD_SUCCESS',
  UPDATE_DEPARTMENT_HEAD_ERROR: 'UPDATE_DEPARTMENT_HEAD_ERROR',

  DEPARTMENT_HEAD_LOADING_All: 'DEPARTMENT_HEAD_LOADING_All',
};




// Concord Distribution Center
export const DISTRIBUTION_CENTER_CONSTANT = {
  DISTRIBUTION_CENTER_LOADING: 'DISTRIBUTION_CENTER_LOADING',
  DISTRIBUTION_CENTER_SUCESS: 'DISTRIBUTION_CENTER_SUCESS',
  DISTRIBUTION_CENTER_ERROR: 'DISTRIBUTION_CENTER_ERROR',

  CREATE_DISTRIBUTION_CENTER_LOADING: 'CREATE_DISTRIBUTION_CENTER_LOADING',
  CREATE_DISTRIBUTION_CENTER_SUCCESS: 'CREATE_DISTRIBUTION_CENTER_SUCCESS',
  CREATE_DISTRIBUTION_CENTER_ERROR: 'CREATE_DISTRIBUTION_CENTER_ERROR',

  UPDATE_DISTRIBUTION_CENTER_LOADING: 'UPDATE_DISTRIBUTION_CENTER_LOADING',
  UPDATE_DISTRIBUTION_CENTER_SUCCESS: 'UPDATE_DISTRIBUTION_CENTER_SUCCESS',
  UPDATE_DISTRIBUTION_CENTER_ERROR: 'UPDATE_DISTRIBUTION_CENTER_ERROR',

  DISTRIBUTION_CENTER_LOADING_All: 'DISTRIBUTION_CENTER_LOADING_All',

  DISTRIBUTION_CENTER_REGION_LOADING: 'DISTRIBUTION_CENTER_REGION_LOADING',
  DISTRIBUTION_CENTER_REGION_SUCESS: 'DISTRIBUTION_CENTER_REGION_SUCESS',
  DISTRIBUTION_CENTER_REGION_ERROR: 'DISTRIBUTION_CENTER_REGION_ERROR',

  DISTRIBUTION_CENTER_VIEW_DEPO_LOADING:
    'DISTRIBUTION_CENTER_VIEW_DEPO_LOADING',
  DISTRIBUTION_CENTER_VIEW_DEPO_SUCCESS:
    'DISTRIBUTION_CENTER_VIEW_DEPO_SUCCESS',
  DISTRIBUTION_CENTER_VIEW_DEPO_ERROR: 'DISTRIBUTION_CENTER_VIEW_DEPO_ERROR',

  DISTRIBUTION_CENTER_GET_AREAS: 'DISTRIBUTION_CENTER_GET_AREAS',
  DISTRIBUTION_CENTER_GET_AREAS_LOADER: 'DISTRIBUTION_CENTER_GET_AREAS_LOADER'
};

// Concord Product Category
export const PRODUCT_CATEGORY_CONSTANT = {
  PRODUCT_CATEGORY_LOADING: 'PRODUCT_CATEGORY_LOADING',
  PRODUCT_CATEGORY_SUCESS: 'PRODUCT_CATEGORY_SUCESS',
  PRODUCT_CATEGORY_ERROR: 'PRODUCT_CATEGORY_ERROR',

  CREATE_PRODUCT_CATEGORY_LOADING: 'CREATE_PRODUCT_CATEGORY_LOADING',
  CREATE_PRODUCT_CATEGORY_SUCCESS: 'CREATE_PRODUCT_CATEGORY_SUCCESS',
  CREATE_PRODUCT_CATEGORY_ERROR: 'CREATE_PRODUCT_CATEGORY_ERROR',

  UPDATE_PRODUCT_CATEGORY_LOADING: 'UPDATE_PRODUCT_CATEGORY_LOADING',
  UPDATE_PRODUCT_CATEGORY_SUCCESS: 'UPDATE_PRODUCT_CATEGORY_SUCCESS',
  UPDATE_PRODUCT_CATEGORY_ERROR: 'UPDATE_PRODUCT_CATEGORY_ERROR',

  PRODUCT_CATEGORY_LOADING_All: 'PRODUCT_CATEGORY_LOADING_All',
};

// Concord Products
export const PRODUCT_CONSTANT = {
  PRODUCT_LOADING: 'PRODUCT_LOADING',
  PRODUCT_SUCESS: 'PRODUCT_SUCESS',
  PRODUCT_ERROR: 'PRODUCT_ERROR',

  CREATE_PRODUCT_LOADING: 'CREATE_PRODUCT_LOADING',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_ERROR: 'CREATE_PRODUCT_ERROR',

  UPDATE_PRODUCT_LOADING: 'UPDATE_PRODUCT_LOADING',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_ERROR: 'UPDATE_PRODUCT_ERROR',

  PRODUCT_GET_CATEGORY: 'PRODUCT_GET_CATEGORY',
  PRODUCT_GET_CATEGORY_LOADER: 'PRODUCT_GET_CATEGORY_LOADER',
  PRODUCT_LOADING_All: 'PRODUCT_LOADING_All',
};

// Concord Stocks
export const STOCKS_CONSTANT = {
  STOCKS_LOADING: 'STOCKS_LOADING',
  STOCKS_SUCESS: 'STOCKS_SUCESS',
  STOCKS_ERROR: 'STOCKS_ERROR',

  CREATE_STOCKS_LOADING: 'CREATE_STOCKS_LOADING',
  CREATE_STOCKS_SUCCESS: 'CREATE_STOCKS_SUCCESS',
  CREATE_STOCKS_ERROR: 'CREATE_STOCKS_ERROR',

  UPDATE_STOCKS_LOADING: 'UPDATE_STOCKS_LOADING',
  UPDATE_STOCKS_SUCCESS: 'UPDATE_STOCKS_SUCCESS',
  UPDATE_STOCKS_ERROR: 'UPDATE_STOCKS_ERROR',


  STOCKS_GET_PRODUCT_CATEGORY: 'STOCKS_GET_PRODUCT_CATEGORY',
  STOCKS_GET_PRODUCT_CATEGORY_LOADER: 'STOCKS_GET_PRODUCT_CATEGORY_LOADER',

  STOCKS_LOADING_All: 'STOCKS_LOADING_All',
};

// Concord StocksTransactions
export const STOCKS_TRANSACTION_CONSTANT = {
  STOCKS_TRANSACTION_LOADING: 'STOCKS_TRANSACTION_LOADING',
  STOCKS_TRANSACTION_SUCESS: 'STOCKS_TRANSACTION_SUCESS',
  STOCKS_TRANSACTION_ERROR: 'STOCKS_TRANSACTION_ERROR',

  CREATE_STOCKS_TRANSACTION_LOADING: 'CREATE_STOCKS_TRANSACTION_LOADING',
  CREATE_STOCKS_TRANSACTION_SUCCESS: 'CREATE_STOCKS_TRANSACTION_SUCCESS',
  CREATE_STOCKS_TRANSACTION_ERROR: 'CREATE_STOCKS_TRANSACTION_ERROR',

  STOCK_DISTRIBUTION_CENTER_CATEGORY: 'STOCK_DISTRIBUTION_CENTER_CATEGORY',
  STOCK_DISTRIBUTION_CENTER_CATEGORY_LOADER: 'STOCK_DISTRIBUTION_CENTER_CATEGORY_LOADER',
  STOCKS_TRANSACTION_LOADING_All: 'STOCKS_TRANSACTION_LOADING_All',
};

// Concord DoctorCategorys
export const DOCTOR_CATEGORY_CONSTANT = {
  DOCTOR_CATEGORY_LOADING: 'DOCTOR_CATEGORY_LOADING',
  DOCTOR_CATEGORY_SUCESS: 'DOCTOR_CATEGORY_SUCESS',
  DOCTOR_CATEGORY_ERROR: 'DOCTOR_CATEGORY_ERROR',

  CREATE_DOCTOR_CATEGORY_LOADING: 'CREATE_DOCTOR_CATEGORY_LOADING',
  CREATE_DOCTOR_CATEGORY_SUCCESS: 'CREATE_DOCTOR_CATEGORY_SUCCESS',
  CREATE_DOCTOR_CATEGORY_ERROR: 'CREATE_DOCTOR_CATEGORY_ERROR',

  UPDATE_DOCTOR_CATEGORY_LOADING: 'UPDATE_DOCTOR_CATEGORY_LOADING',
  UPDATE_DOCTOR_CATEGORY_SUCCESS: 'UPDATE_DOCTOR_CATEGORY_SUCCESS',
  UPDATE_DOCTOR_CATEGORY_ERROR: 'UPDATE_DOCTOR_CATEGORY_ERROR',

  DOCTOR_CATEGORY_LOADING_All: 'DOCTOR_CATEGORY_LOADING_All',
};

// Concord Doctors
export const DOCTOR_CONSTANT = {
  DOCTOR_LOADING: 'DOCTOR_LOADING',
  DOCTOR_SUCESS: 'DOCTOR_SUCESS',
  DOCTOR_ERROR: 'DOCTOR_ERROR',

  CREATE_DOCTOR_LOADING: 'CREATE_DOCTOR_LOADING',
  CREATE_DOCTOR_SUCCESS: 'CREATE_DOCTOR_SUCCESS',
  CREATE_DOCTOR_ERROR: 'CREATE_DOCTOR_ERROR',

  UPDATE_DOCTOR_LOADING: 'UPDATE_DOCTOR_LOADING',
  UPDATE_DOCTOR_SUCCESS: 'UPDATE_DOCTOR_SUCCESS',
  UPDATE_DOCTOR_ERROR: 'UPDATE_DOCTOR_ERROR',

  DOCTOR_LOADING_All: 'DOCTOR_LOADING_All',

  DOCTOR_GET_HIERARCHY_REGION: 'DOCTOR_GET_HIERARCHY_REGION',
  DOCTOR_GET_HIERARCHY_REGION_LOADER: 'DOCTOR_GET_HIERARCHY_REGION_LOADER',
  DOCTOR_GET_HIERARCHY_AREA: 'DOCTOR_GET_HIERARCHY_AREA',
  DOCTOR_GET_HIERARCHY_AREA_LOADER: 'DOCTOR_GET_HIERARCHY_AREA_LOADER',
  DOCTOR_GET_HIERARCHY_THANA: 'DOCTOR_GET_HIERARCHY_THANA',
  DOCTOR_GET_HIERARCHY_THANA_LOADER: 'DOCTOR_GET_HIERARCHY_THANA_LOADER',
  DOCTOR_GET_HIERARCHY_TERRITORY: 'DOCTOR_GET_HIERARCHY_TERRITORY',
  DOCTOR_GET_HIERARCHY_TERRITORY_LOADER: 'DOCTOR_GET_HIERARCHY_TERRITORY_LOADER',
  DOCTOR_GET_HIERARCHY_MARKET: 'DOCTOR_GET_HIERARCHY_MARKET',
  DOCTOR_GET_HIERARCHY_MARKET_LOADER: 'DOCTOR_GET_HIERARCHY_MARKET_LOADER',

};

// Concord Customers
export const CUSTOMER_CONSTANT = {
  CUSTOMER_LOADING: 'CUSTOMER_LOADING',
  CUSTOMER_SUCESS: 'CUSTOMER_SUCESS',
  CUSTOMER_ERROR: 'CUSTOMER_ERROR',

  CREATE_CUSTOMER_LOADING: 'CREATE_CUSTOMER_LOADING',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR: 'CREATE_CUSTOMER_ERROR',

  UPDATE_CUSTOMER_LOADING: 'UPDATE_CUSTOMER_LOADING',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_ERROR: 'UPDATE_CUSTOMER_ERROR',

  CUSTOMER_LOADING_All: 'CUSTOMER_LOADING_All',
};
export const VIEW_DELIVERY_STAFF_CONSTANT = {
  VIEW_DELIVERY_STAFF_LOADING: "VIEW_DELIVERY_STAFF_LOADING",
  VIEW_DELIVERY_STAFF_SUCCESS: "VIEW_DELIVERY_STAFF_SUCCESS",
  VIEW_DELIVERY_STAFF_ERROR: "VIEW_DELIVERY_STAFF_ERROR",
}
export const CREATE_DELIVERY_STAFF_CONSTANT = {
  CREATE_DELIVERY_STAFF_LOADING: "CREATE_DELIVERY_STAFF_LOADING",
  CREATE_DELIVERY_STAFF_SUCCESS: "CREATE_DELIVERY_STAFF_SUCCESS",
  CREATE_DELIVERY_STAFF_ERROR: "CREATE_DELIVERY_STAFF_ERROR",
}
export const EDIT_DELIVERY_STAFF_CONSTANT = {
  EDIT_DELIVERY_STAFF_LOADING: "EDIT_DELIVERY_STAFF_LOADING",
  EDIT_DELIVERY_STAFF_SUCCESS: "EDIT_DELIVERY_STAFF_SUCCESS",
  EDIT_DELIVERY_STAFF_ERROR: "EDIT_DELIVERY_STAFF_ERROR",
}



// CMS CONSTANTS

export const cmsConstants = {
  COM_LOADER: 'COM_LOADER',
  CMS_LOADER: 'CMS_LOADER',
  CHANGE_DATA: 'CHANGE_DATA',

  READ_COMPONENT: 'READ_COMPONENT',
  CREATE_COMPONENT: 'CREATE_COMPONENT',
  UPDATE_COMPONENT: 'UPDATE_COMPONENT',
  SUSPEND_COMPONENT: 'SUSPEND_COMPONENT',

  READ_PAGE: 'READ_PAGE',
  CREATE_PAGE: 'CREATE_PAGE',
  UPDATE_PAGE: 'UPDATE_PAGE',
  SUSPEND_PAGE: 'SUSPEND_PAGE',

  READ_LANG: 'READ_LANG',
  CREATE_LANG: 'CREATE_LANG',
  UPDATE_LANG: 'UPDATE_LANG',
  SUSPEND_LANG: 'SUSPEND_LANG',


}
export const DOCTOR_SPECIALITY = {
  READ_DOCTOR_SPECIALITY: 'READ_DOCTOR_SPECIALITY',
  READ_DOCTOR_SPECIALITY_LOADING: 'READ_DOCTOR_SPECIALITY_LOADING',
  READ_DOCTOR_SPECIALITY_ERROR: 'READ_DOCTOR_SPECIALITY_ERROR',

  CREATE_DOCTOR_SPECIALITY: 'CREATE_DOCTOR_SPECIALITY',
  UPDATE_DOCTOR_SPECIALITY: 'UPDATE_DOCTOR_SPECIALITY',
  SUSPEND_DOCTOR_SPECIALITY: 'SUSPEND_DOCTOR_SPECIALITY',
}

// export const STATICDATACONSTANT = {
//   STATIC_DATA:'STATIC_DATA',
// };


export const FETCH_STATIC_DATA = "FETCH_STATIC_DATA"


export const VIEW_PARTIAL_PACKAGE_CONSTANT = {
  VIEW_PARTIAL_PACKAGE_LOADING: 'VIEW_PARTIAL_PACKAGE_LOADING',
  VIEW_PARTIAL_PACKAGE_SUCCESS: 'VIEW_PARTIAL_PACKAGE_SUCCESS',
  VIEW_PARTIAL_PACKAGE_ERROR: 'VIEW_PARTIAL_PACKAGE_ERROR'
}