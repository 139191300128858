/* eslint-disable */

import {
  VIEW_DELIVERY_STAFF_CONSTANT,
  CREATE_DELIVERY_STAFF_CONSTANT,
  EDIT_DELIVERY_STAFF_CONSTANT,
} from '../../../Constant/Constants';
const initial_state = {
  deliveryStaff: [],
  createDeliveryStaff: {},
  loading: false,
};
export const DeliveryStaffReducer = (state=initial_state, action) => {
  switch (action.type) {
    case VIEW_DELIVERY_STAFF_CONSTANT.VIEW_DELIVERY_STAFF_LOADING:
      return { ...state, loading: action?.payload };

    case VIEW_DELIVERY_STAFF_CONSTANT.VIEW_DELIVERY_STAFF_SUCCESS:
      return { ...state, deliveryStaff: action?.payload };

    case VIEW_DELIVERY_STAFF_CONSTANT.VIEW_DELIVERY_STAFF_ERROR:
      return { ...state, loading: action?.payload };
    case CREATE_DELIVERY_STAFF_CONSTANT.CREATE_DELIVERY_STAFF_LOADING:
      return { ...state, loading: action?.payload };

    case CREATE_DELIVERY_STAFF_CONSTANT.CREATE_DELIVERY_STAFF_SUCCESS:
      return { ...state, createDeliveryStaff: action?.payload };

    case CREATE_DELIVERY_STAFF_CONSTANT.CREATE_DELIVERY_STAFF_ERROR:
      return { ...state, loading: action?.payload };
    case EDIT_DELIVERY_STAFF_CONSTANT.EDIT_DELIVERY_STAFF_LOADING:
      return { ...state, loading: action?.payload };

    case EDIT_DELIVERY_STAFF_CONSTANT.EDIT_DELIVERY_STAFF_SUCCESS:
      return { ...state, createDeliveryStaff: action?.payload };

    case EDIT_DELIVERY_STAFF_CONSTANT.EDIT_DELIVERY_STAFF_ERROR:
      return { ...state, loading: action?.payload };

    default:
      return {...state};
  }
};
