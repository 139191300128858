/* eslint-disable */
export const LOGIN_CONSTANTS = {
    LOGIN_LOADING : "LOGIN_LOADING",
    LOGIN_SUCESS : "LOGIN_SUCESS" ,
    LOGIN_ERROR:'LOGIN_ERROR',
}
export const FORGOT_CONSTANTS = {
    FORGOT_LOADING : "FORGOT_LOADING",
    FORGOT_SUCESS : "FORGOT_SUCESS" ,
    FORGOT_ERROR:'FORGOT_ERROR'
}
export const SET_USER_FROM_LOCAL='SET_USER_FROM_LOCAL';
export const VIEW_ORDER_CONSTANTS={
    VIEW_ORDER_LOADING:'VIEW_ORDER_LOADING',
    VIEW_ORDER_SUCESS:'VIEW_ORDER_SUCESS',
    VIEW_ORDER_ERROR:'VIEW_ORDER_ERROR'

}
export const VIEW_RESCHEDULE_REQUEST = {
    VIEW_RESCHEDULE_REQUEST_LOADING:'VIEW_RESCHEDULE_REQUEST_LOADING',
    VIEW_RESCHEDULE_REQUEST_SUCESS:'VIEW_RESCHEDULE_REQUEST_SUCESS',
    VIEW_RESCHEDULE_REQUEST_ERROR:'VIEW_RESCHEDULE_REQUEST_ERROR'
}
export const UPDATE_RESCHEDULE_REQUEST = {
    UPDATE_RESCHEDULE_REQUEST_LOADING:'UPDATE_RESCHEDULE_REQUEST_LOADING',
    UPDATE_RESCHEDULE_REQUEST_SUCESS:'UPDATE_RESCHEDULE_REQUEST_SUCESS',
    UPDATE_RESCHEDULE_REQUEST_ERROR:'UPDATE_RESCHEDULE_REQUEST_ERROR'
}
export const GET_AVILABLE_SLOTS = {
    GET_AVILABLE_SLOTS_LOADING:'GET_AVILABLE_SLOTS_LOADING',
    GET_AVILABLE_SLOTS_SUCESS:'GET_AVILABLE_SLOTS_SUCESS',
    GET_AVILABLE_SLOTS_ERROR:'GET_AVILABLE_SLOTS_ERROR'
}
export const VIEW_CURRENT_ORDER_CONSTANTS={
    VIEW_CURRENT_ORDER_LOADING:'VIEW_CURRENT_ORDER_LOADING',
    VIEW_CURRENT_ORDER_SUCESS:'VIEW_CURRENT_ORDER_SUCESS',
    VIEW_CURRENT_ORDER_ERROR:'VIEW_CURRENT_ORDER_ERROR'

}
export const VIEW_REMOVAL_REQUEST_CONSTANTS = {
    VIEW_REMOVAL_REQUEST_LOADING:'VIEW_REMOVAL_REQUEST_LOADING',
    VIEW_REMOVAL_REQUEST_SUCESS:'VIEW_REMOVAL_REQUEST_SUCESS',
    VIEW_REMOVAL_REQUEST_ERROR:'VIEW_REMOVAL_REQUEST_ERROR'

}
export const ACCEPT_REMOVAL_REQUEST_CONSTANTS = {
    ACCEPT_REMOVAL_REQUEST_LOADING:'ACCEPT_REMOVAL_REQUEST_LOADING',
    ACCEPT_REMOVAL_REQUEST_SUCESS:'ACCEPT_REMOVAL_REQUEST_SUCESS',
    ACCEPT_REMOVAL_REQUEST_ERROR:'ACCEPT_REMOVAL_REQUEST_ERROR'

}
export const REJECT_REMOVAL_REQUEST_CONSTANTS = {
    REJECT_REMOVAL_REQUEST_LOADING:'REJECT_REMOVAL_REQUEST_LOADING',
    REJECT_REMOVAL_REQUEST_SUCESS:'REJECT_REMOVAL_REQUEST_SUCESS',
    REJECT_REMOVAL_REQUEST_ERROR:'REJECT_REMOVAL_REQUEST_ERROR'

}
export const VIEW_CURRENT_REMOVAL_REQUEST_CONSTANTS = {
    VIEW_CURRENT_REMOVAL_REQUEST_LOADING:'VIEW_CURRENT_REMOVAL_REQUEST_LOADING',
    VIEW_CURRENT_REMOVAL_REQUEST_SUCESS:'VIEW_CURRENT_REMOVAL_REQUEST_SUCESS',
    VIEW_CURRENT_REMOVAL_REQUEST_ERROR:'VIEW_CURRENT_REMOVAL_REQUEST_ERROR'

}
export const VIEW_CURRENT_APPOINMTENT_REQUEST_CONSTANTS = {
    VIEW_CURRENT_APPOINMTENT_REQUEST_LOADING:'VIEW_CURRENT_APPOINMTENT_REQUEST_LOADING',
    VIEW_CURRENT_APPOINMTENT_REQUEST_SUCESS:'VIEW_CURRENT_APPOINMTENT_REQUEST_SUCESS',
    VIEW_CURRENT_APPOINMTENT_REQUEST_ERROR:'VIEW_CURRENT_APPOINMTENT_REQUEST_ERROR'

}
export const CANCAL_APPOINTMENT_REQEUEST = {
    CANCAL_APPOINTMENT_REQEUEST_LOADING:'CANCAL_APPOINTMENT_REQEUEST_LOADING',
    CANCAL_APPOINTMENT_REQEUEST_SUCESS:'CANCAL_APPOINTMENT_REQEUEST_SUCESS',
    CANCAL_APPOINTMENT_REQEUEST_ERROR:'CANCAL_APPOINTMENT_REQEUEST_ERROR'

}
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHANGE_PASSWORD_CONSTANT = {
    CHANGE_PASSWORD_LOADING : "CHANGE_PASSWORD_LOADING",
    CHANGE_PASSWORD_SUCESS : "CHANGE_PASSWORD_SUCESS" ,
    CHANGE_PASSWORD_ERROR:'CHANGE_PASSWORD_ERROR'
}
export const CREATE_DOCTOR_CONSTANT = {
    CREATE_DOCTOR_LOADING:"CREATE_DOCTOR_LOADING",
    CREATE_DOCTOR_SUCESS:"CREATE_DOCTOR_SUCESS",
    CREATE_DOCTOR_ERROR:"CREATE_DOCTOR_ERROR",
}
export const VIEW_DELIVERY_STAFF_CONSTANT = {
    VIEW_DELIVERY_STAFF_LOADING:"VIEW_DELIVERY_STAFF_LOADING",
    VIEW_DELIVERY_STAFF_SUCCESS:"VIEW_DELIVERY_STAFF_SUCCESS",
    VIEW_DELIVERY_STAFF_ERROR:"VIEW_DELIVERY_STAFF_ERROR",
}
export const CREATE_DELIVERY_STAFF_CONSTANT = {
    CREATE_DELIVERY_STAFF_LOADING:"CREATE_DELIVERY_STAFF_LOADING",
    CREATE_DELIVERY_STAFF_SUCCESS:"CREATE_DELIVERY_STAFF_SUCCESS",
    CREATE_DELIVERY_STAFF_ERROR:"CREATE_DELIVERY_STAFF_ERROR",
}
export const EDIT_DELIVERY_STAFF_CONSTANT = {
    EDIT_DELIVERY_STAFF_LOADING:"EDIT_DELIVERY_STAFF_LOADING",
    EDIT_DELIVERY_STAFF_SUCCESS:"EDIT_DELIVERY_STAFF_SUCCESS",
    EDIT_DELIVERY_STAFF_ERROR:"EDIT_DELIVERY_STAFF_ERROR",
}
export const VIEW_BDE_CONSTANT = {
    VIEW_BDE_LOADING:"VIEW_BDE_LOADING",
    VIEW_BDE_SUCCESS:"VIEW_BDE_SUCCESS",
    VIEW_BDE_ERROR:"VIEW_BDE_ERROR",
}
export const VIEW_DCP_CONSTANT = {
    VIEW_DCP_LOADING: 'VIEW_DCP_LOADING',
    VIEW_DCP_SUCCESS: 'VIEW_DCP_SUCCESS',
    VIEW_DCP_ERROR: 'VIEW_DCP_ERROR',
  };
  export const VIEW_DCR_CONSTANT = {
    VIEW_DCR_LOADING: 'VIEW_DCR_LOADING',
    VIEW_DCR_SUCCESS: 'VIEW_DCR_SUCCESS',
    VIEW_DCR_ERROR: 'VIEW_DCR_ERROR',
  };
  export const CREATE_DCP_CONSTANT = {
    CREATE_DCP_LOADING: 'CREATE_DCP_LOADING',
    CREATE_DCP_SUCCESS: 'CREATE_DCP_SUCCESS',
    CREATE_DCP_ERROR: 'CREATE_DCP_ERROR',
  };
  
  export const VIEW_SCHEDULE_CONSTANT = {
    VIEW_SCHEDULE_LOADING: 'VIEW_SCHEDULE_LOADING',
    VIEW_SCHEDULE_SUCCESS: 'VIEW_SCHEDULE_SUCCESS',
    VIEW_SCHEDULE_ERROR: 'VIEW_SCHEDULE_ERROR',
  };
  
  export const SUSPAND_DCP_DCR_CONSTANT = {
    SUSPAND_DCP_DCR_LOADING: 'SUSPAND_DCP_DCR_LOADING',
    SUSPAND_DCP_DCR_SUCCESS: 'SUSPAND_DCP_DCR_SUCCESS',
    SUSPAND_DCP_DCR_ERROR: 'SUSPAND_DCP_DCR_ERROR',
  };
  
export const CREATE_BDE_CONSTANT = {
    CREATE_BDE_LOADING:"CREATE_BDE_LOADING",
    CREATE_BDE_SUCCESS:"CREATE_BDE_SUCCESS",
    CREATE_BDE_ERROR:"CREATE_BDE_ERROR",
}
export const EDIT_BDE_CONSTANT = {
    EDIT_BDE_LOADING:"EDIT_BDE_LOADING",
    EDIT_BDE_SUCCESS:"EDIT_BDE_SUCCESS",
    EDIT_BDE_ERROR:"EDIT_BDE_ERROR",
}

export const VIEW_DOCTOR_CONSTANT = {
    VIEW_DOCTOR_LOADING:"VIEW_DOCTOR_LOADING",
    VIEW_DOCTOR_SUCESS:"VIEW_DOCTOR_SUCESS",
    VIEW_DOCTOR_ERROR:"VIEW_DOCTOR_ERROR",
}
export const VIEW_CURRENT_DOCTOR_CONSTANT = {
    VIEW_CURRENT_DOCTOR_LOADING:"VIEW_CURRENT_DOCTOR_LOADING",
    VIEW_CURRENT_DOCTOR_SUCESS:"VIEW_CURRENT_DOCTOR_SUCESS",
    VIEW_CURRENT_DOCTOR_ERROR:"VIEW_CURRENT_DOCTOR_ERROR",
}
export const UPDATE_DOCTOR_CONSTANT = {
    UPDATE_DOCTOR_LOADING:'UPDATE_DOCTOR_LOADING',
    UPDATE_DOCTOR_SUCESS:'UPDATE_DOCTOR_SUCESS',
    UPDATE_DOCTOR_ERROR:'UPDATE_DOCTOR_ERROR'
}
export const CREATE_ADMINISTRATOR_CONSTANT = {
    CREATE_ADMINISTRATOR_LOADING:'CREATE_ADMINISTRATOR_LOADING',
    CREATE_ADMINISTRATOR_SUCESS:'CREATE_ADMINISTRATOR_SUCESS',
    CREATE_ADMINISTRATOR_ERROR:'CREATE_ADMINISTRATOR_ERROR'
}
export const UPDATE_ADMINISTRATOR_CONSTANT = {
    UPDATE_ADMINISTRATOR_LOADING:'UPDATE_ADMINISTRATOR_LOADING',
    UPDATE_ADMINISTRATOR_SUCESS:'UPDATE_ADMINISTRATOR_SUCESS',
    UPDATE_ADMINISTRATOR_ERROR:'UPDATE_ADMINISTRATOR_ERROR'
}
export const VIEW_ADMINISTRATOR_CONSTANT = {
    VIEW_ADMINISTRATOR_LOADING:"VIEW_ADMINISTRATOR_LOADING",
    VIEW_ADMINISTRATOR_SUCESS:"VIEW_ADMINISTRATOR_SUCESS",
    VIEW_ADMINISTRATOR_ERROR:"VIEW_ADMINISTRATOR_ERROR",
}
export const VIEW_CURRENT_ADMINISTRATOR_CONSTANT = {
    VIEW_CURRENT_ADMINISTRATOR_LOADING:"VIEW_CURRENT_ADMINISTRATOR_LOADING",
    VIEW_CURRENT_ADMINISTRATOR_SUCESS:"VIEW_CURRENT_ADMINISTRATOR_SUCESS",
    VIEW_CURRENT_ADMINISTRATOR_ERROR:"VIEW_CURRENT_ADMINISTRATOR_ERROR",
}



export const CREATE_LABADMIN_CONSTANT = {
    CREATE_LABADMIN_LOADING:'CREATE_LABADMIN_LOADING',
    CREATE_LABADMIN_SUCESS:'CREATE_LABADMIN_SUCESS',
    CREATE_LABADMIN_ERROR:'CREATE_LABADMIN_ERROR'
}
export const UPDATE_LABADMIN_CONSTANT = {
    UPDATE_LABADMIN_LOADING:'UPDATE_LABADMIN_LOADING',
    UPDATE_LABADMIN_SUCESS:'UPDATE_LABADMIN_SUCESS',
    UPDATE_LABADMIN_ERROR:'UPDATE_LABADMIN_ERROR'
}

export const VIEW_LABADMIN_CONSTANT = {
    VIEW_LABADMIN_LOADING:"VIEW_LABADMIN_LOADING",
    VIEW_LABADMIN_SUCESS:"VIEW_LABADMIN_SUCESS",
    VIEW_LABADMIN_ERROR:"VIEW_LABADMIN_ERROR",
}
export const VIEW_CURRENT_LABADMIN_CONSTANT = {
    VIEW_CURRENT_LABADMIN_LOADING:"VIEW_CURRENT_LABADMIN_LOADING",
    VIEW_CURRENT_LABADMIN_SUCESS:"VIEW_CURRENT_LABADMIN_SUCESS",
    VIEW_CURRENT_LABADMIN_ERROR:"VIEW_CURRENT_LABADMIN_ERROR",
}




export const CREATE_PHARMACY_CONSTANT = {
    CREATE_PHARMACY_LOADING:'CREATE_PHARMACY_LOADING',
    CREATE_PHARMACY_SUCESS:'CREATE_PHARMACY_SUCESS',
    CREATE_PHARMACY_ERROR:'CREATE_PHARMACY_ERROR'
}
export const UPDATE_PHARMACY_CONSTANT = {
    UPDATE_PHARMACY_LOADING:'UPDATE_PHARMACY_LOADING',
    UPDATE_PHARMACY_SUCESS:'UPDATE_PHARMACY_SUCESS',
    UPDATE_PHARMACY_ERROR:'UPDATE_PHARMACY_ERROR'
}


export const VIEW_PHARMACY_CONSTANT = {
    VIEW_PHARMACY_LOADING:"VIEW_PHARMACY_LOADING",
    VIEW_PHARMACY_SUCESS:"VIEW_PHARMACY_SUCESS",
    VIEW_PHARMACY_ERROR:"VIEW_PHARMACY_ERROR",
}
export const VIEW_CURRENT_PHARMACY_CONSTANT = {
    VIEW_CURRENT_PHARMACY_LOADING:"VIEW_CURRENT_PHARMACY_LOADING",
    VIEW_CURRENT_PHARMACY_SUCESS:"VIEW_CURRENT_PHARMACY_SUCESS",
    VIEW_CURRENT_PHARMACY_ERROR:"VIEW_CURRENT_PHARMACY_ERROR",
}




export const CREATE_SUPERADMIN_CONSTANT = {
    CREATE_SUPERADMIN_LOADING:'CREATE_SUPERADMIN_LOADING',
    CREATE_SUPERADMIN_SUCESS:'CREATE_SUPERADMIN_SUCESS',
    CREATE_SUPERADMIN_ERROR:'CREATE_SUPERADMIN_ERROR'
}
export const UPDATE_SUPERADMIN_CONSTANT = {
    UPDATE_SUPERADMIN_LOADING:'UPDATE_SUPERADMIN_LOADING',
    UPDATE_SUPERADMIN_SUCESS:'UPDATE_SUPERADMIN_SUCESS',
    UPDATE_SUPERADMIN_ERROR:'UPDATE_SUPERADMIN_ERROR'
}

export const VIEW_SUPERADMIN_CONSTANT = {
    VIEW_SUPERADMIN_LOADING:"VIEW_SUPERADMIN_LOADING",
    VIEW_SUPERADMIN_SUCESS:"VIEW_SUPERADMIN_SUCESS",
    VIEW_SUPERADMIN_ERROR:"VIEW_SUPERADMIN_ERROR",
}
export const VIEW_CURRENT_SUPERADMIN_CONSTANT = {
    VIEW_CURRENT_SUPERADMIN_LOADING:"VIEW_CURRENT_SUPERADMIN_LOADING",
    VIEW_CURRENT_SUPERADMIN_SUCESS:"VIEW_CURRENT_SUPERADMIN_SUCESS",
    VIEW_CURRENT_SUPERADMIN_ERROR:"VIEW_CURRENT_SUPERADMIN_ERROR",
}


export const GET_DEPARTMENT_CONSTANT = {
    GET_DEPARTMENT_LOADING:'GET_DEPARTMENT_LOADING',
    GET_DEPARTMENT_SUCESS:'GET_DEPARTMENT_SUCESS',
    GET_DEPARTMENT_ERROR:'GET_DEPARTMENT_ERROR',
    

}




export const CREATE_PATIENT_CONSTANT = {
    CREATE_PATIENT_LOADING:'CREATE_PATIENT_LOADING',
    CREATE_PATIENT_SUCESS:'CREATE_PATIENT_SUCESS',
    CREATE_PATIENT_ERROR:'CREATE_PATIENT_ERROR'
}
export const UPDATE_PATIENT_CONSTANT = {
    UPDATE_PATIENT_LOADING:'UPDATE_PATIENT_LOADING',
    UPDATE_PATIENT_SUCESS:'UPDATE_PATIENT_SUCESS',
    UPDATE_PATIENT_ERROR:'UPDATE_PATIENT_ERROR'
}
export const VIEW_PATIENT_CONSTANT = {
    VIEW_PATIENT_LOADING:"VIEW_PATIENT_LOADING",
    VIEW_PATIENT_SUCESS:"VIEW_PATIENT_SUCESS",
    VIEW_PATIENT_ERROR:"VIEW_PATIENT_ERROR",
}
export const VIEW_CURRENT_PATIENT_CONSTANT = {
    VIEW_CURRENT_PATIENT_LOADING:"VIEW_CURRENT_PATIENT_LOADING",
    VIEW_CURRENT_PATIENT_SUCESS:"VIEW_CURRENT_PATIENT_SUCESS",
    VIEW_CURRENT_PATIENT_ERROR:"VIEW_CURRENT_PATIENT_ERROR",
}


export const UPDATE_ORDER_CONSTANT ={
    UPDATE_ORDER_LOADING:'UPDATE_ORDER_LOADING',
    UPDATE_ORDER_SUCESS:'UPDATE_ORDER_SUCESS',
    UPDATE_ORDER_ERROR:'UPDATE_ORDER_ERROR'
}

export const ASSIGN_ORDER_CONSTANT ={
    ASSIGN_ORDER_LOADING:'ASSIGN_ORDER_LOADING',
    ASSIGN_ORDER_SUCESS:'ASSIGN_ORDER_SUCESS',
    ASSIGN_ORDER_ERROR:'ASSIGN_ORDER_ERROR'
}

export const VIEW_TEST_CONSTANT={
    VIEW_TEST_LOADING :'VIEW_TEST_LOADING',
    VIEW_TEST_SUCESS:'VIEW_TEST_SUCESS',
    VIEW_TEST_ERROR:'VIEW_TEST_ERROR'
}

export const UPDATE_TEST_CONSTANT={
    UPDATE_TEST_LOADING :'UPDATE_TEST_LOADING',
    UPDATE_TEST_SUCESS:'UPDATE_TEST_SUCESS',
    UPDATE_TEST_ERROR:'UPDATE_TEST_ERROR'
}

export const UPDATE_CATEGORY_CONSTANT={
    UPDATE_CATEGORY_LOADING :'UPDATE_CATEGORY_LOADING',
    UPDATE_CATEGORY_SUCESS:'UPDATE_CATEGORY_SUCESS',
    UPDATE_CATEGORY_ERROR:'UPDATE_CATEGORY_ERROR'
}
export const READ_REGION_CONSTANT={
    READ_REGION_LOADING:'READ_REGION_LOADING',
    READ_REGION_SUCCESS:'READ_REGION_SUCCESS',
    READ_REGION_ERROR:'READ_REGION_ERROR'
}
export const READR_CATEGORY_CONSTANT ={
    READR_CATEGORY_LOADING:'READR_CATEGORY_LOADING',
    READR_CATEGORY_SUCESS:'READR_CATEGORY_SUCESS',
    READR_CATEGORY_ERROR:'READR_CATEGORY_ERROR'
}; 
export const CREATE_TEST_CONSTANT ={
    CREATE_TEST_LOADING:'CREATE_TEST_LOADING',
    CREATE_TEST_SUCESS:'CREATE_TEST_SUCESS',
    CREATE_TEST_ERROR:'CREATE_TEST_ERROR'
}
export const CREATE_CATEGORY_CONSTANT ={
    CREATE_CATEGORY_LOADING:'CREATE_CATEGORY_LOADING',
    CREATE_CATEGORY_SUCESS:'CREATE_CATEGORY_SUCESS',
    CREATE_CATEGORY_ERROR:'CREATE_CATEGORY_ERROR'
}

export const VIEW_MEDICINE_CONSTANT={
    VIEW_MEDICINES_LOADING:'VIEW_MEDICINES_LOADING',
    VIEW_MEDICINES_SUCESS:'VIEW_MEDICINES_SUCESS',
    VIEW_MEDICINES_ERROR:'VIEW_MEDICINES_ERROR'
}
export const CREATE_MEDICINE_CONSTANT={
    CREATE_MEDICINES_LOADING:'CREATE_MEDICINES_LOADING',
    CREATE_MEDICINES_SUCESS:'CREATE_MEDICINES_SUCESS',
    CREATE_MEDICINES_ERROR:'CREATE_MEDICINES_ERROR'
}
export const UPDATE_MEDICINE_CONSTANT={
    UPDATE_MEDICINES_LOADING:'UPDATE_MEDICINES_LOADING',
    UPDATE_MEDICINES_SUCESS:'UPDATE_MEDICINES_SUCESS',
    UPDATE_MEDICINES_ERROR:'UPDATE_MEDICINES_ERROR'
}

export const VIEW_REPORT_CONSTANT={
    VIEW_REPORT_LOADING:'VIEW_REPORT_LOADING',
    VIEW_REPORT_SUCCESS:'VIEW_REPORT_SUCCESS',
    VIEW_REPORT_ERROR:'VIEW_REPORT_ERROR'
}


export const VIEW_PACKAGE_CONSTANT = {
    VIEW_PACKAGE_LOADING:'VIEW_PACKAGE_LOADING',
    VIEW_PACKAGE_SUCCESS:'VIEW_PACKAGE_SUCCESS',
    VIEW_PACKAGE_ERROR:'VIEW_PACKAGE_ERROR'
}
export const CREATE_PACKAGE_CONSTANT = {
    CREATE_PACKAGE_LOADING:'CREATE_PACKAGE_LOADING',
    CREATE_PACKAGE_SUCCESS:'CREATE_PACKAGE_SUCCESS',
    CREATE_PACKAGE_ERROR:'CREATE_PACKAGE_ERROR'
}
export const UPDATE_PACKAGE_CONSTANT = {
    UPDATE_PACKAGE_LOADING:'UPDATE_PACKAGE_LOADING',
    UPDATE_PACKAGE_SUCCESS:'UPDATE_PACKAGE_SUCCESS',
    UPDATE_PACKAGE_ERROR:'UPDATE_PACKAGE_ERROR'
}

export const REGION_CLASSIFICAION_CONSTANT = {
    CREATE_REGION_CLASSIFICAION_LOADING:'CREATE_REGION_CLASSIFICAION_LOADING',
    CREATE_REGION_CLASSIFICAION_SUCCESS:'CREATE_REGION_CLASSIFICAION_SUCCESS',
    CREATE_REGION_CLASSIFICAION_ERROR:'CREATE_REGION_CLASSIFICAION_ERROR'
}

export const UPDATE_REGION_CLASSIFICAION_CONSTANT = {
    UPDATE_REGION_CLASSIFICAION_LOADING:'UPDATE_REGION_CLASSIFICAION_LOADING',
    UPDATE_REGION_CLASSIFICAION_SUCCESS:'UPDATE_REGION_CLASSIFICAION_SUCCESS',
    UPDATE_REGION_CLASSIFICAION_ERROR:'UPDATE_REGION_CLASSIFICAION_ERROR'
}

export const VIEW_DEPARTMENT_CONSTANT ={
    VIEW_DEPARTMENT_LOADING:'VIEW_DEPARTMENT_LOADING',
    VIEW_DEPARTMENT_SUCCESS:'VIEW_DEPARTMENT_SUCCESS',
    VIEW_DEPARTMENT_ERROR:'VIEW_DEPARTMENT_ERROR'
}
export const UPDATE_DEPARTMENT_CONSTANT ={
    UPDATE_DEPARTMENT_LOADING:'UPDATE_DEPARTMENT_LOADING',
    UPDATE_DEPARTMENT_SUCCESS:'UPDATE_DEPARTMENT_SUCCESS',
    UPDATE_DEPARTMENT_ERROR:'UPDATE_DEPARTMENT_ERROR'
}


export const VIEW_TARGET_CONSTANT = {
    VIEW_TARGET_LOADING: 'VIEW_TARGET_LOADING',
    VIEW_TARGET_SUCCESS: 'VIEW_TARGET_SUCCESS',
    VIEW_TARGET_ERROR: 'VIEW_TARGET_ERROR',
  };
  export const CREATE_TARGET_CONSTANT = {
    CREATE_TARGET_LOADING: 'CREATE_TARGET_LOADING',
    CREATE_TARGET_SUCCESS: 'CREATE_TARGET_SUCCESS',
    CREATE_TARGET_ERROR: 'CREATE_TARGET_ERROR',
  };
  export const EDIT_TARGET_CONSTANT = {
    EDIT_TARGET_LOADING: 'EDIT_TARGET_LOADING',
    EDIT_TARGET_SUCCESS: 'EDIT_TARGET_SUCCESS',
    EDIT_TARGET_ERROR: 'EDIT_TARGET_ERROR',
  };
  
  export const SUSPAND_TARGET_CONSTANT = {
    SUSPAND_TARGET_LOADING: 'SUSPAND_TARGET_LOADING',
    SUSPAND_TARGET_SUCCESS: 'SUSPAND_TARGET_SUCCESS',
    SUSPAND_TARGET_ERROR: 'SUSPAND_TARGET_ERROR',
  };

  
  export const APPROVE_TARGET_CONSTANT = {
    APPROVE_TARGET_LOADING: 'APPROVE_TARGET_LOADING',
    APPROVE_TARGET_SUCCESS: 'APPROVE_TARGET_SUCCESS',
    APPROVE_TARGET_ERROR: 'APPROVE_TARGET_ERROR',
  };
  

  export const READ_DOCTOR_LOCATION = {
    READ_DOCTOR_LOCATION_LOADING: 'READ_DOCTOR_LOCATION_LOADING',
    READ_DOCTOR_LOCATION_SUCCESS: 'READ_DOCTOR_LOCATION_SUCCESS',
    READ_SPECIFIC_DOCTOR_LOCATION_SUCCESS: 'READ_SPECIFIC_LOCATION_SUCCESS',
    READ_SPECIFIC_DOCTOR_LOCATION_LOADING: 'READ_SPECIFIC_LOCATION_LOADING',
    READ_SPECIFIC_DOCTOR_LOCATION_ERROR: 'READ_SPECIFIC_LOCATION_ERROR',
  
  
  
    READ_DOCTOR_LOCATION_ERROR: 'READ_DOCTOR_LOCATION_ERROR'
  }
  export const CREATE_DOCTOR_LOCATION = {
    CREATE_DOCTOR_LOCATION_LOADING: 'CREATE_DOCTOR_LOCATION_LOADING',
    CREATE_DOCTOR_LOCATION_SUCCESS: 'CREATE_DOCTOR_LOCATION_SUCCESS',
    CREATE_DOCTOR_LOCATION_ERROR: 'CREATE_DOCTOR_LOCATION_ERROR'
  }
  export const UPDATE_DOCTOR_LOCATION = {
    UPDATE_DOCTOR_LOCATION_LOADING: 'UPDATE_DOCTOR_LOCATION_LOADING',
    UPDATE_DOCTOR_LOCATION_SUCCESS: 'UPDATE_DOCTOR_LOCATION_SUCCESS',
    UPDATE_DOCTOR_LOCATION_ERROR: 'UPDATE_DOCTOR_LOCATION_ERROR'
  }
  
  
  export const ASSIGN_LOCATION = {
    ASSIGN_DOCTOR_LOCATION_LOADING: 'ASSIGN_DOCTOR_LOCATION_LOADING',
    ASSIGN_DOCTOR_LOCATION_SUCCESS: 'ASSIGN_DOCTOR_LOCATION_SUCCESS',
    ASSIGN_DOCTOR_LOCATION_ERROR: 'ASSIGN_DOCTOR_LOCATION_ERROR'
  }
  