/* eslint-disable */

import {
  VIEW_BDE_CONSTANT,
  CREATE_BDE_CONSTANT,
  EDIT_BDE_CONSTANT,
} from 'Store/Constant/Constants';
const initial_state = {
  bde: [],
  createBde: {},
  loading: false,
};
export const BDEReducer = (state=initial_state, action) => {
  switch (action.type) {
    case VIEW_BDE_CONSTANT.VIEW_BDE_LOADING:
      return { ...state, loading: action?.payload };

    case VIEW_BDE_CONSTANT.VIEW_BDE_SUCCESS:
      return { ...state, bde: action?.payload };

    case VIEW_BDE_CONSTANT.VIEW_BDE_ERROR:
      return { ...state, loading: action?.payload };
    case CREATE_BDE_CONSTANT.CREATE_BDE_LOADING:
      return { ...state, loading: action?.payload };

    case CREATE_BDE_CONSTANT.CREATE_BDE_SUCCESS:
      return { ...state, createBde: action?.payload };

    case CREATE_BDE_CONSTANT.CREATE_BDE_ERROR:
      return { ...state, loading: action?.payload };
    case EDIT_BDE_CONSTANT.EDIT_BDE_LOADING:
      return { ...state, loading: action?.payload };

    case EDIT_BDE_CONSTANT.EDIT_BDE_SUCCESS:
      return { ...state, createBde: action?.payload };

    case EDIT_BDE_CONSTANT.EDIT_BDE_ERROR:
      return { ...state, loading: action?.payload };

    default:
      return { ...state };
  }
};
