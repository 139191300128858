/* eslint-disable */

import {
  VIEW_TARGET_CONSTANT,
  CREATE_TARGET_CONSTANT,
  EDIT_TARGET_CONSTANT,
  APPROVE_TARGET_CONSTANT
} from 'Store/Constant/Constants';

const initial_state = {
  target: [],
  order: [],
  loading: false,
  distributionCenter: [],
  createTarget: {},
  updateTarget: {},
  approveTarget: {}
};

export const TargetReducer = (state = initial_state, action) => {
  switch (action.type) {
    case VIEW_TARGET_CONSTANT.VIEW_TARGET_LOADING:
      return { ...state, loading: action.payload };
    case VIEW_TARGET_CONSTANT.VIEW_TARGET_SUCCESS:
      return { ...state, target: action.payload };
    case VIEW_TARGET_CONSTANT.VIEW_TARGET_ERROR:
      return { ...state, loading: action.payload };
    case CREATE_TARGET_CONSTANT.CREATE_TARGET_LOADING:
      return { ...state, loading: action.payload };
    case CREATE_TARGET_CONSTANT.CREATE_TARGET_SUCCESS:
      return { ...state, createTarget: action.payload };
    case CREATE_TARGET_CONSTANT.CREATE_TARGET_ERROR:
      return { ...state, loading: action.payload };
    case EDIT_TARGET_CONSTANT.EDIT_TARGET_LOADING:
      return { ...state, loading: action.payload };
    case EDIT_TARGET_CONSTANT.EDIT_TARGET_SUCCESS:
      return { ...state, updateTarget: action.payload };
    case EDIT_TARGET_CONSTANT.EDIT_TARGET_ERROR:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};
