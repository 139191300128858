/* eslint-disable */

import {UPDATE_DEPARTMENT_CONSTANT} from 'Store/Constant/Constants'

const initail_state ={
    department:[],
    loading:false,
    updateDepartment:[]
}

export const DepartmentReducer = (state = initail_state, action) => {
    switch (action.type) {
      case UPDATE_DEPARTMENT_CONSTANT.UPDATE_DEPARTMENT_LOADING:
        return { ...state, updateDepartment: action.payload,slots:[] };
      case UPDATE_DEPARTMENT_CONSTANT.UPDATE_DEPARTMENT_SUCCESS:
        return { ...state, updateDepartment: action.payload, loading: false };
      case UPDATE_DEPARTMENT_CONSTANT.UPDATE_DEPARTMENT_ERROR:
        return { ...state, updateDepartment: action.payload, loading: false };
      default:
        return state;
    }
  };