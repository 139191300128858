/* eslint-disable */

import { READ_DOCTOR_LOCATION, CREATE_DOCTOR_LOCATION, UPDATE_DOCTOR_LOCATION } from 'Store/Constant/Constants'

const initial_state = {
    doctorLocation: [],
    filteredLocation: [],
    loading: false,
    loadingFilter: false,

}
export const DoctorLocationReducer = (state = initial_state, action) => {
    switch (action.type) {
        case READ_DOCTOR_LOCATION.READ_DOCTOR_LOCATION_LOADING:
            return { ...state, loading: action.payload }
        case READ_DOCTOR_LOCATION.READ_DOCTOR_LOCATION_SUCCESS:
            return { ...state, doctorLocation: action.payload }


        case READ_DOCTOR_LOCATION.READ_DOCTOR_LOCATION_ERROR:
            return { ...state, loading: action.payload }
        case READ_DOCTOR_LOCATION.READ_SPECIFIC_DOCTOR_LOCATION_LOADING:
            return { ...state, loadingFilter: action.payload }
        case READ_DOCTOR_LOCATION.READ_SPECIFIC_DOCTOR_LOCATION_SUCCESS:
            return { ...state, filteredLocation: action.payload }
        case READ_DOCTOR_LOCATION.READ_SPECIFIC_DOCTOR_LOCATION_ERROR:
            return { ...state, loadingFilter: action.payload }
        default:
            return state;
    }
}

