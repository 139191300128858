/* eslint-disable */

import {
    DOCTOR_SPECIALITY
} from 'redux/Constant/Constants';

const initail_state = {
    speciality: [],
    loading: false,
    createSpeciality: [],
    updateSpeciality: [],
};
export const ViewSpecialityReducer = (state = initail_state, action) => {
    switch (action.type) {
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_LOADING:
            return { ...state, loading: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY:
            return { ...state, speciality: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_ERROR:
            return { ...state, loading: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_LOADING:
            return { ...state, loading: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_LOADING:
            return { ...state, createSpeciality: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_ERROR:
            return { ...state, loading: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_LOADING:
            return { ...state, loading: action.payload };
        case DOCTOR_SPECIALITY.UPDATE_DOCTOR_SPECIALITY:
            return { ...state, updateSpeciality: action.payload };
        case DOCTOR_SPECIALITY.READ_DOCTOR_SPECIALITY_ERROR:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};
